import React from 'react';
import { Table, TableColumnsType, Tag, Tooltip } from 'antd';
import { Link } from 'react-router-dom';
import { StarOutlined } from '@ant-design/icons';
import { useAuth } from '../../contexts/auth';
import { UserType } from './UserType';

type UserListProps = {
  users: UserType[];
  isLoading: boolean;
};

export default function UserList({ users, isLoading }: UserListProps) {
  const { isAdmin } = useAuth();

  const columns: TableColumnsType<UserType> = [
    {
      title: '#',
      dataIndex: 'id',
      key: 'id',
      sorter: (a: UserType, b: UserType) => (a.id > b.id ? 1 : -1),
    },
    {
      title: 'Empresa',
      dataIndex: 'enterpriseName',
      sorter: (a: UserType, b: UserType) =>
        a.enterpriseName.localeCompare(b.enterpriseName),
      key: 'enterpriseName',
    },
    {
      title: 'Nome',
      dataIndex: 'name',
      sorter: (a: UserType, b: UserType) => a.name.localeCompare(b.name),
      key: 'name',
      render: (text, record) => <Link to={`/users/${record.id}`}>{text}</Link>,
    },
    {
      title: 'E-mail',
      dataIndex: 'email',
      sorter: (a: UserType, b: UserType) => a.email.localeCompare(b.email),
      key: 'email',
      render: (text, record) => (
        <span>
          {text}
          {record.specialCondition && (
            <>
              &nbsp;
              <Tooltip
                title={`Condição especial: ${record.specialConditionName}`}
              >
                <Tag color='gold'>
                  <StarOutlined />
                </Tag>
              </Tooltip>
            </>
          )}
        </span>
      ),
    },
    {
      title: 'Ativo?',
      dataIndex: 'isActive',
      key: 'isActive',
      render: (isActive) =>
        isActive ? <Tag color='green'>Sim</Tag> : <Tag>Não</Tag>,
    },
    {
      title: 'Trial',
      dataIndex: 'localizedTrialEndsAt',
      key: 'localizedTrialEndsAt',
      sorter: (a: UserType, b: UserType) =>
        a.trialEndsAt.localeCompare(b.trialEndsAt),
      render: (value: string) =>
        value.length > 0 ? <span>Até {value}</span> : <span>Não</span>,
    },
    {
      title: 'Criado em',
      dataIndex: 'localizedCreatedAt',
      key: 'localizedCreatedAt',
      sorter: (a: UserType, b: UserType) =>
        a.createdAt.localeCompare(b.createdAt),
    },
    {
      title: 'Último acesso',
      dataIndex: 'localizedLastLoginAt',
      key: 'localizedLastLoginAt',
      sorter: (a: UserType, b: UserType) =>
        a.lastLoginAt.localeCompare(b.lastLoginAt),
    },
    {
      title: 'Atalhos',
      dataIndex: '',
      key: 'links',
      width: 120,
      render: (_, record) => (
        <strong>
          <small>
            <Link to={`/logs-user/${record.id}`}>LOGS</Link>
            &nbsp;|&nbsp;
            <Link to={`/dashboard/${record.id}`}>DASH</Link>
          </small>
        </strong>
      ),
    },
  ];

  return isAdmin() ? (
    <Table
      loading={isLoading}
      size='small'
      dataSource={users}
      columns={columns}
      pagination={false}
    />
  ) : (
    <div />
  );
}
