import React, { useEffect, useState } from 'react';
import type { DragEndEvent } from '@dnd-kit/core';
import {
  DndContext,
  PointerSensor,
  useSensor,
  useSensors,
} from '@dnd-kit/core';
import { restrictToVerticalAxis } from '@dnd-kit/modifiers';
import {
  arrayMove,
  SortableContext,
  useSortable,
  verticalListSortingStrategy,
} from '@dnd-kit/sortable';
import { CSS } from '@dnd-kit/utilities';
import { message, TableColumnsType, Table } from 'antd';
import api from '../../../services/api';
import { handleApiError } from '../../../services/functions';
import { useAuth } from '../../../contexts/auth';

interface DataType {
  key: string;
  name: string;
  url: string;
  index: number;
}

const columns: TableColumnsType<DataType> = [
  {
    title: 'Ordem',
    dataIndex: 'index',
  },
  {
    title: 'Name',
    dataIndex: 'name',
  },
  {
    title: 'URL',
    dataIndex: 'url',
  },
];

interface RowProps extends React.HTMLAttributes<HTMLTableRowElement> {
  'data-row-key': string;
}

// eslint-disable-next-line react/function-component-definition
const Row: React.FC<Readonly<RowProps>> = (props) => {
  const {
    attributes,
    listeners,
    setNodeRef,
    transform,
    transition,
    isDragging,
  } = useSortable({
    // eslint-disable-next-line react/destructuring-assignment
    id: props['data-row-key'],
  });

  const style: React.CSSProperties = {
    // eslint-disable-next-line react/destructuring-assignment
    ...props.style,
    transform: CSS.Translate.toString(transform),
    transition,
    cursor: 'move',
    ...(isDragging ? { position: 'relative', zIndex: 9999 } : {}),
  };

  return (
    <tr
      {...props}
      ref={setNodeRef}
      style={style}
      {...attributes}
      {...listeners}
    />
  );
};

export default function AiConfig() {
  const { isAdmin } = useAuth();

  const [isLoading, setIsLoading] = useState(false);

  const [dataSource, setDataSource] = useState<DataType[]>([]);

  async function getDifyPrecedence() {
    if (!isAdmin()) {
      return;
    }

    setIsLoading(true);

    try {
      const response = await api.get('/ai-config/dify-routing');
      const { data } = response.data;

      setDataSource(
        data.map((d: any, i: number) => ({
          index: i + 1,
          ...d,
        }))
      );
    } catch (e: any) {
      handleApiError(e);
    }

    setIsLoading(false);
  }

  const setDifyPrecedence = async (key: string) => {
    setIsLoading(true);

    try {
      await api.post(`/ai-config/dify-routing/${key}`);
      message.success('Precedência configurada com sucesso');
      await getDifyPrecedence();
    } catch (e) {
      handleApiError(e); // TODO: Tratar erro exibindo no formulário
    }

    setIsLoading(false);
  };

  const sensors = useSensors(
    useSensor(PointerSensor, {
      activationConstraint: {
        // https://docs.dndkit.com/api-documentation/sensors/pointer#activation-constraints
        distance: 1,
      },
    })
  );

  const onDragEnd = async ({ active, over }: DragEndEvent) => {
    if (active.id !== over?.id) {
      let newDataTypes: DataType[] = [];
      setDataSource((prev) => {
        const activeIndex = prev.findIndex((i) => i.key === active.id);
        const overIndex = prev.findIndex((i) => i.key === over?.id);
        newDataTypes = arrayMove(prev, activeIndex, overIndex);
        return newDataTypes;
      });
      await setDifyPrecedence(newDataTypes[0].key);
    }
  };

  useEffect(() => {
    getDifyPrecedence();
  }, []);

  return (
    <>
      <div>
        Arraste um registro para o topo da tabela para tornar o roteamento
        padrão
      </div>
      <DndContext
        sensors={sensors}
        modifiers={[restrictToVerticalAxis]}
        onDragEnd={onDragEnd}
      >
        <SortableContext
          // rowKey array
          items={dataSource.map((i) => i.key)}
          strategy={verticalListSortingStrategy}
        >
          <Table<DataType>
            components={{
              body: { row: Row },
            }}
            rowKey='key'
            columns={columns}
            dataSource={dataSource}
            loading={isLoading}
            pagination={false}
          />
        </SortableContext>
      </DndContext>
    </>
  );
}
