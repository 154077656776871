/* eslint-disable prettier/prettier */
import React, { useState, useEffect } from 'react';
import { Button, Input, message, Tabs } from 'antd';
import type { TabsProps } from 'antd';
import api from '../../services/api';
import AiConfig from './AIConfig/AiConfig';

const { TextArea } = Input;

export default function Config() {
  const [tabSel, setTabSel] = useState('ai_speech');

  const [promptDE, setPromptDE] = useState('');
  const [promptAR, setPromptAR] = useState('');
  const [promptES, setPromptES] = useState('');
  const [promptFR, setPromptFR] = useState('');
  const [promptEN, setPromptEN] = useState('');
  const [promptIT, setPromptIT] = useState('');
  const [promptBR, setPromptBR] = useState('');
  const [promptPT, setPromptPT] = useState('');

  const [promptAssistantDE, setPromptAssistantDE] = useState(''); // v1 & v2
  const [promptAssistantAR, setPromptAssistantAR] = useState(''); // v1 & v2
  const [promptAssistantES, setPromptAssistantES] = useState(''); // v1 & v2
  const [promptAssistantFR, setPromptAssistantFR] = useState(''); // v1 & v2
  const [promptAssistantEN, setPromptAssistantEN] = useState(''); // v1 & v2
  const [promptAssistantIT, setPromptAssistantIT] = useState(''); // v1 & v2
  const [promptAssistantBR, setPromptAssistantBR] = useState(''); // v1 & v2
  const [promptAssistantPT, setPromptAssistantPT] = useState(''); // v1 & v2

  const [promptFastAgentDE, setPromptFastAgentDE] = useState('');
  const [promptFastAgentAR, setPromptFastAgentAR] = useState('');
  const [promptFastAgentES, setPromptFastAgentES] = useState('');
  const [promptFastAgentFR, setPromptFastAgentFR] = useState('');
  const [promptFastAgentEN, setPromptFastAgentEN] = useState('');
  const [promptFastAgentIT, setPromptFastAgentIT] = useState('');
  const [promptFastAgentBR, setPromptFastAgentBR] = useState('');
  const [promptFastAgentPT, setPromptFastAgentPT] = useState('');

  const [messageApi, contextHolder] = message.useMessage();

  useEffect(() => {
    const fetchConfig = async () => {
      const response = await api.get('/configurations/general-settings');
      const { data } = response.data;

      // AI SPEECH

      if (data.promptDE) {
        setPromptDE(data.promptDE);
      }
      if (data.promptAR) {
        setPromptAR(data.promptAR);
      }
      if (data.promptES) {
        setPromptES(data.promptES);
      }
      if (data.promptFR) {
        setPromptFR(data.promptFR);
      }
      if (data.promptEN) {
        setPromptEN(data.promptEN);
      }
      if (data.promptIT) {
        setPromptIT(data.promptIT);
      }
      if (data.promptBR) {
        setPromptBR(data.promptBR);
      }
      if (data.promptPT) {
        setPromptPT(data.promptPT);
      }

      // AI ASSISTANT - v1 & v2

      if (data.promptAssistantDE) {
        setPromptAssistantDE(data.promptAssistantDE);
      }
      if (data.promptAssistantAR) {
        setPromptAssistantAR(data.promptAssistantAR);
      }
      if (data.promptAssistantES) {
        setPromptAssistantES(data.promptAssistantES);
      }
      if (data.promptAssistantFR) {
        setPromptAssistantFR(data.promptAssistantFR);
      }
      if (data.promptAssistantEN) {
        setPromptAssistantEN(data.promptAssistantEN);
      }
      if (data.promptAssistantIT) {
        setPromptAssistantIT(data.promptAssistantIT);
      }
      if (data.promptAssistantBR) {
        setPromptAssistantBR(data.promptAssistantBR);
      }
      if (data.promptAssistantPT) {
        setPromptAssistantPT(data.promptAssistantPT);
      }

      // FAST AGENT

      if (data.promptFastAgentDE) {
        setPromptFastAgentDE(data.promptFastAgentDE);
      }
      if (data.promptFastAgentAR) {
        setPromptFastAgentAR(data.promptFastAgentAR);
      }
      if (data.promptFastAgentES) {
        setPromptFastAgentES(data.promptFastAgentES);
      }
      if (data.promptFastAgentFR) {
        setPromptFastAgentFR(data.promptFastAgentFR);
      }
      if (data.promptFastAgentEN) {
        setPromptFastAgentEN(data.promptFastAgentEN);
      }
      if (data.promptFastAgentIT) {
        setPromptFastAgentIT(data.promptFastAgentIT);
      }
      if (data.promptFastAgentBR) {
        setPromptFastAgentBR(data.promptFastAgentBR);
      }
      if (data.promptFastAgentPT) {
        setPromptFastAgentPT(data.promptFastAgentPT);
      }
    };
    fetchConfig();
  }, []);

  const updateConfig = async () => {
    const response = await api.put('/configurations/general-settings', {
      promptDE,
      promptAR,
      promptES,
      promptFR,
      promptEN,
      promptIT,
      promptBR,
      promptPT,

      promptAssistantDE,
      promptAssistantAR,
      promptAssistantES,
      promptAssistantFR,
      promptAssistantEN,
      promptAssistantIT,
      promptAssistantBR,
      promptAssistantPT,

      promptFastAgentDE,
      promptFastAgentAR,
      promptFastAgentES,
      promptFastAgentFR,
      promptFastAgentEN,
      promptFastAgentIT,
      promptFastAgentBR,
      promptFastAgentPT,
    });
    messageApi.open({
      type: 'success',
      content: 'Dados atualizados com sucesso!',
    });
  };

  const itemsAiSpeech: TabsProps['items'] = [
    {
      key: 'de',
      label: 'Alemão',
      children: (
        <div>
          <h3>Prompt AI Speech em Alemão</h3>
          <TextArea
            rows={15}
            value={promptDE}
            onChange={(e) => setPromptDE(e.target.value)}
          />
        </div>
      ),
    },
    {
      key: 'ar',
      label: 'Árabe',
      children: (
        <div>
          ø<h3>Prompt AI Speech em Árabe</h3>
          <TextArea
            rows={15}
            value={promptAR}
            onChange={(e) => setPromptAR(e.target.value)}
          />
        </div>
      ),
    },
    {
      key: 'es',
      label: 'Espanhol',
      children: (
        <div>
          <h3>Prompt AI Speech em Espanhol</h3>
          <TextArea
            rows={15}
            value={promptES}
            onChange={(e) => setPromptES(e.target.value)}
          />
        </div>
      ),
    },
    {
      key: 'fr',
      label: 'Francês',
      children: (
        <div>
          <h3>Prompt AI Speech em Francês</h3>
          <TextArea
            rows={15}
            value={promptFR}
            onChange={(e) => setPromptFR(e.target.value)}
          />
        </div>
      ),
    },
    {
      key: 'en',
      label: 'Inglês',
      children: (
        <div>
          <h3>Prompt AI Speech em Inglês</h3>
          <TextArea
            rows={15}
            value={promptEN}
            onChange={(e) => setPromptEN(e.target.value)}
          />
        </div>
      ),
    },
    {
      key: 'it',
      label: 'Italiano',
      children: (
        <div>
          <h3>Prompt AI Speech em Italiano</h3>
          <TextArea
            rows={15}
            value={promptIT}
            onChange={(e) => setPromptIT(e.target.value)}
          />
        </div>
      ),
    },
    {
      key: 'br',
      label: 'Português Brasil',
      children: (
        <div>
          <h3>Prompt AI Speech em Português Brasil</h3>
          <TextArea
            rows={15}
            value={promptBR}
            onChange={(e) => setPromptBR(e.target.value)}
          />
        </div>
      ),
    },
    {
      key: 'pt',
      label: 'Português Portugal',
      children: (
        <div>
          <h3>Prompt AI Speech em Português Portugal</h3>
          <TextArea
            rows={15}
            value={promptPT}
            onChange={(e) => setPromptPT(e.target.value)}
          />
        </div>
      ),
    },
  ];

  const itemsAiAssistant: TabsProps['items'] = [
    {
      key: 'de',
      label: 'Alemão',
      children: (
        <div>
          <h3>Prompt AI Assistant em Alemão</h3>
          <TextArea
            rows={15}
            value={promptAssistantDE}
            onChange={(e) => setPromptAssistantDE(e.target.value)}
          />
        </div>
      ),
    },
    {
      key: 'ar',
      label: 'Árabe',
      children: (
        <div>
          <h3>Prompt AI Assistant em Árabe</h3>
          <TextArea
            rows={15}
            value={promptAssistantAR}
            onChange={(e) => setPromptAssistantAR(e.target.value)}
          />
        </div>
      ),
    },
    {
      key: 'es',
      label: 'Espanhol',
      children: (
        <div>
          <h3>Prompt AI Assistant em Espanhol</h3>
          <TextArea
            rows={15}
            value={promptAssistantES}
            onChange={(e) => setPromptAssistantES(e.target.value)}
          />
        </div>
      ),
    },
    {
      key: 'fr',
      label: 'Francês',
      children: (
        <div>
          <h3>Prompt AI Assistant em Francês</h3>
          <TextArea
            rows={15}
            value={promptAssistantFR}
            onChange={(e) => setPromptAssistantFR(e.target.value)}
          />
        </div>
      ),
    },
    {
      key: 'en',
      label: 'Inglês',
      children: (
        <div>
          <h3>Prompt AI Assistant em Inglês</h3>
          <TextArea
            rows={15}
            value={promptAssistantEN}
            onChange={(e) => setPromptAssistantEN(e.target.value)}
          />
        </div>
      ),
    },
    {
      key: 'it',
      label: 'Italiano',
      children: (
        <div>
          <h3>Prompt AI Assistant em Italiano</h3>
          <TextArea
            rows={15}
            value={promptAssistantIT}
            onChange={(e) => setPromptAssistantIT(e.target.value)}
          />
        </div>
      ),
    },
    {
      key: 'br',
      label: 'Português Brasil',
      children: (
        <div>
          <h3>Prompt AI Assistant em Português Brasil</h3>
          <TextArea
            rows={15}
            value={promptAssistantBR}
            onChange={(e) => setPromptAssistantBR(e.target.value)}
          />
        </div>
      ),
    },
    {
      key: 'pt',
      label: 'Português Portugal',
      children: (
        <div>
          <h3>Prompt AI Assistant em Português Portugal</h3>
          <TextArea
            rows={15}
            value={promptAssistantPT}
            onChange={(e) => setPromptAssistantPT(e.target.value)}
          />
        </div>
      ),
    },
  ];

  const itemsFastAgent: TabsProps['items'] = [
    {
      key: 'de',
      label: 'Alemão',
      children: (
        <div>
          <h3>Prompt Fast Agent em Alemão</h3>
          <TextArea
            rows={15}
            value={promptFastAgentDE}
            onChange={(e) => setPromptFastAgentDE(e.target.value)}
          />
        </div>
      ),
    },
    {
      key: 'ar',
      label: 'Árabe',
      children: (
        <div>
          <h3>Prompt Fast Agent em Árabe</h3>
          <TextArea
            rows={15}
            value={promptFastAgentAR}
            onChange={(e) => setPromptFastAgentAR(e.target.value)}
          />
        </div>
      ),
    },
    {
      key: 'es',
      label: 'Espanhol',
      children: (
        <div>
          <h3>Prompt Fast Agent em Espanhol</h3>
          <TextArea
            rows={15}
            value={promptFastAgentES}
            onChange={(e) => setPromptFastAgentES(e.target.value)}
          />
        </div>
      ),
    },
    {
      key: 'fr',
      label: 'Francês',
      children: (
        <div>
          <h3>Prompt Fast Agent em Francês</h3>
          <TextArea
            rows={15}
            value={promptFastAgentFR}
            onChange={(e) => setPromptFastAgentFR(e.target.value)}
          />
        </div>
      ),
    },
    {
      key: 'en',
      label: 'Inglês',
      children: (
        <div>
          <h3>Prompt Fast Agent em Inglês</h3>
          <TextArea
            rows={15}
            value={promptFastAgentEN}
            onChange={(e) => setPromptFastAgentEN(e.target.value)}
          />
        </div>
      ),
    },
    {
      key: 'it',
      label: 'Italiano',
      children: (
        <div>
          <h3>Prompt Fast Agent em Italiano</h3>
          <TextArea
            rows={15}
            value={promptFastAgentIT}
            onChange={(e) => setPromptFastAgentIT(e.target.value)}
          />
        </div>
      ),
    },
    {
      key: 'br',
      label: 'Português Brasil',
      children: (
        <div>
          <h3>Prompt Fast Agent em Português Brasil</h3>
          <TextArea
            rows={15}
            value={promptFastAgentBR}
            onChange={(e) => setPromptFastAgentBR(e.target.value)}
          />
        </div>
      ),
    },
    {
      key: 'pt',
      label: 'Português Portugal',
      children: (
        <div>
          <h3>Prompt Fast Agent em Português Portugal</h3>
          <TextArea
            rows={15}
            value={promptFastAgentPT}
            onChange={(e) => setPromptFastAgentPT(e.target.value)}
          />
        </div>
      ),
    },
  ];

  const itemsMenu: TabsProps['items'] = [
    {
      key: 'ai_speech',
      label: 'Prompt do AI Speech',
      children: <Tabs tabPosition='left' items={itemsAiSpeech} />,
    },
    {
      key: 'ai_assistant',
      label: 'Prompt do AI Assistant (v1 e v2)',
      children: <Tabs tabPosition='left' items={itemsAiAssistant} />,
    },
    {
      key: 'fast_agent',
      label: 'Prompt do Fast Agent',
      children: <Tabs tabPosition='left' items={itemsFastAgent} />,
    },
    {
      key: 'dify',
      label: 'Dify',
      children: <AiConfig />,
    },
  ];

  const onChange = (key: string) => {
    setTabSel(key);
  };

  return (
    <div>
      {contextHolder}
      <h1>Configurações</h1>
      <Tabs items={itemsMenu} onChange={onChange} />
      {tabSel !== 'dify' && (
        <Button
          style={{ marginTop: 20 }}
          block
          type='primary'
          onClick={updateConfig}
        >
          ATUALIZAR
        </Button>
      )}
    </div>
  );
}
