import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { Breadcrumb, Typography } from 'antd';
import { HomeOutlined, ProfileOutlined } from '@ant-design/icons';
import { useAuth } from '../../contexts/auth';
import api from '../../services/api';
import { handleApiError } from '../../services/functions';
import UserList from '../../components/User/UserList';
import { UserType } from '../../components/User/UserType';
import parseUsers from '../../components/User/parseUsers';

const { Title } = Typography;

type EnterpriseType = {
  id: number;
  key: number;
  name: string;
  users: UserType[];
};

export default function EnterprisesShowPage() {
  const { isAdmin } = useAuth();
  const { enterpriseId } = useParams();

  const [isLoading, setIsLoading] = useState(false);
  const [enterprise, setEnterprise] = useState<EnterpriseType | null>(null);

  async function getEnterprise() {
    if (!isAdmin()) {
      return;
    }
    setIsLoading(true);

    try {
      const { data } = await api.get(`/admin/enterprises/${enterpriseId}`);
      setEnterprise({
        ...data,
        users: parseUsers(data.users),
      });
    } catch (e) {
      handleApiError(e);
    }

    setIsLoading(false);
  }

  useEffect(() => {
    getEnterprise();
  }, [enterpriseId]);

  return isAdmin() ? (
    <>
      <Breadcrumb
        items={[
          {
            href: '/',
            title: <HomeOutlined />,
          },
          {
            href: '/enterprises',
            title: (
              <>
                <ProfileOutlined />
                <span>Empresas</span>
              </>
            ),
          },
          {
            href: `/enterprises/${enterpriseId}`,
            title: enterprise?.name || 'Carregando...',
          },
        ]}
        style={{ marginBottom: '1.5rem' }}
      />
      <Title level={2}>{enterprise?.name || 'Carregando...'}</Title>
      <UserList users={enterprise?.users || []} isLoading={isLoading} />
    </>
  ) : (
    <div />
  );
}
