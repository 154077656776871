import React, { useState } from 'react';
import {
  Button,
  DatePicker,
  Flex,
  Form,
  Input,
  message,
  Typography,
} from 'antd';
import { FaCheck } from 'react-icons/fa6';
import dayjs from 'dayjs';
import api from '../../services/api';
import { handleApiError } from '../../services/functions';

type UserTrialProps = {
  userId: number;
  initialValue: string;
  setTrialEndsAt: (trialEndsAt: string) => void;
};

export default function UserTrial({
  userId,
  initialValue,
  setTrialEndsAt,
}: UserTrialProps) {
  const [form] = Form.useForm();

  const [isLoading, setIsLoading] = useState(false);
  const [hasSucceeded, setHasSucceeded] = useState(false);

  const setTrial = async (newTrialEndsAt: string) => {
    setIsLoading(true);

    try {
      const { data } = await api.post(`/admin/users/${userId}/set-trial`, {
        trialEndsAt: newTrialEndsAt,
      });

      message.success('Trial configurado com sucesso');
      setTrialEndsAt(data.trialEndsAt);
      setTimeout(() => {
        setHasSucceeded(false);
      }, 2000);
      setHasSucceeded(true);
    } catch (e) {
      handleApiError(e); // TODO: Tratar erro exibindo no formulário
    }

    setIsLoading(false);
  };

  const onFinish = (values: any) => {
    setTrial(values.trialEndsAt);
  };

  return (
    <Flex justify='space-between' align='center'>
      <div>
        <strong style={{ fontSize: 'large' }}>Configurar Trial</strong>
        <br />
        Configurar a data em que o trial expira
      </div>
      <Form layout='inline' form={form} onFinish={onFinish}>
        <Form.Item
          name='trialEndsAt'
          initialValue={initialValue ? dayjs(initialValue) : undefined}
        >
          <DatePicker format='DD/MM/YYYY' minDate={dayjs()} />
        </Form.Item>
        <Form.Item style={{ margin: 0 }}>
          <Button
            color='danger'
            variant='solid'
            htmlType='submit'
            loading={isLoading}
            icon={hasSucceeded ? <FaCheck /> : null}
          >
            Configurar Trial
          </Button>
        </Form.Item>
      </Form>
    </Flex>
  );
}
