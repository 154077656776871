import React, { useEffect, useState } from 'react';
import { Table, TableColumnsType } from 'antd';
import { Link } from 'react-router-dom';
import { useAuth } from '../../contexts/auth';
import api from '../../services/api';
import { handleApiError } from '../../services/functions';

type EnterpriseType = {
  id: number;
  key: number;
  name: string;
  totalUserCount: number;
  activeUsersCount: number;
};

export default function EnterprisesIndexPage() {
  const { isAdmin } = useAuth();

  const [isLoading, setIsLoading] = useState(false);
  const [enterprises, setEnterprises] = useState([] as EnterpriseType[]);

  async function getEnterprises() {
    if (!isAdmin()) {
      return;
    }

    setIsLoading(true);

    try {
      const response = await api.get('/admin/enterprises');
      const { data } = response.data;

      setEnterprises(data);
    } catch (e: any) {
      handleApiError(e);
    }

    setIsLoading(false);
  }

  useEffect(() => {
    getEnterprises();
  }, []);

  const columns: TableColumnsType<EnterpriseType> = [
    {
      title: '#',
      dataIndex: 'id',
      key: 'id',
      sorter: (a: EnterpriseType, b: EnterpriseType) => (a.id > b.id ? 1 : -1),
    },
    {
      title: 'Nome',
      dataIndex: 'name',
      sorter: (a: EnterpriseType, b: EnterpriseType) =>
        a.name.localeCompare(b.name),
      key: 'name',
      render: (text, record) => (
        <Link to={`/enterprises/${record.id}`}>{text}</Link>
      ),
    },
    {
      title: 'Usuários',
      dataIndex: 'totalUserCount',
      key: 'totalUserCount',
      align: 'right',
      sorter: (a: EnterpriseType, b: EnterpriseType) =>
        a.totalUserCount > b.totalUserCount ? 1 : -1,
    },
    {
      title: 'Ativos',
      dataIndex: 'activeUsersCount',
      key: 'activeUsersCount',
      align: 'right',
      sorter: (a: EnterpriseType, b: EnterpriseType) =>
        a.activeUsersCount > b.activeUsersCount ? 1 : -1,
    },
  ];

  return isAdmin() ? (
    <div>
      <h1>Empresas</h1>
      <Table
        loading={isLoading}
        size='small'
        dataSource={enterprises}
        columns={columns}
        pagination={false}
      />
    </div>
  ) : (
    <div />
  );
}
