/* eslint-disable prettier/prettier */
import React from 'react';
import { Line } from '@ant-design/plots';

export default function DailyReports(props: any) {
  const { data } = props;

  const config = {
    data,
    xField: 'name',
    yField: 'count',
    point: {
      shapeField: 'square',
      sizeField: 4,
    },
    interaction: {
      tooltip: {
        marker: false,
      },
    },
    style: {
      lineWidth: 2,
    },
  };

  return <Line {...config} />;
}
