/* eslint-disable prettier/prettier */
import React, { useEffect, useRef } from 'react';
import { Column } from '@ant-design/plots';

export default function ColumnChart(props: any) {
  const { data, slider } = props;
  const chartRef = useRef(null);

  const config = {
    data,
    xField: 'name',
    yField: 'count',
    axis: {
      x: {
        label: false,
      },
    },
    slider: {
      x: {
        values: slider,
      },
    },
  };

  return <Column {...config} ref={chartRef} />;
}
