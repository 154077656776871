import React, { useEffect, useState } from 'react';
import { useAuth } from '../../contexts/auth';
import api from '../../services/api';
import { handleApiError } from '../../services/functions';
import UserList from '../../components/User/UserList';
import parseUsers from '../../components/User/parseUsers';
import { UserType } from '../../components/User/UserType';

export default function UsersIndexPage() {
  const { isAdmin } = useAuth();

  const [isLoading, setIsLoading] = useState(false);
  const [users, setUsers] = useState([] as UserType[]);

  async function getUsers() {
    if (!isAdmin()) {
      return;
    }

    setIsLoading(true);

    try {
      const response = await api.get('/admin/users');
      const { data } = response.data;

      setUsers(parseUsers(data));
    } catch (e: any) {
      handleApiError(e);
    }

    setIsLoading(false);
  }

  useEffect(() => {
    getUsers();
  }, []);

  return isAdmin() ? (
    <div>
      <h1>Usuários</h1>
      <UserList users={users} isLoading={isLoading} />
    </div>
  ) : (
    <div />
  );
}
