import React, { useState } from 'react';
import { Button, Flex, Form, Input, message, Typography } from 'antd';
import { FaCheck } from 'react-icons/fa6';
import api from '../../services/api';
import { handleApiError } from '../../services/functions';

const { Title } = Typography;

type UsersResetPasswordProps = {
  userId: number;
};

export default function UsersResetPassword({
  userId,
}: UsersResetPasswordProps) {
  const [form] = Form.useForm();

  const [isLoading, setIsLoading] = useState(false);
  const [hasSucceeded, setHasSucceeded] = useState(false);

  const resetPassword = async (password: string) => {
    setIsLoading(true);

    try {
      await api.post(`/admin/users/${userId}/reset-password`, {
        password,
      });

      message.success('Senha alterada com sucesso');
      form.resetFields();
      setTimeout(() => {
        setHasSucceeded(false);
      }, 2000);
      setHasSucceeded(true);
    } catch (e) {
      handleApiError(e); // TODO: Tratar erro exibindo no formulário
    }

    setIsLoading(false);
  };

  const onFinish = (values: any) => {
    resetPassword(values.password);
  };

  return (
    <Flex justify='space-between' align='center'>
      <div>
        <strong style={{ fontSize: 'large' }}>Redefinir Senha</strong>
        <br />
        Especificar uma nova senha para o usuário
      </div>
      <Form layout='inline' form={form} onFinish={onFinish}>
        <Form.Item name='password'>
          <Input type='password' required placeholder='Nova senha' />
        </Form.Item>
        <Form.Item style={{ margin: 0 }}>
          <Button
            color='danger'
            variant='solid'
            htmlType='submit'
            loading={isLoading}
            icon={hasSucceeded ? <FaCheck /> : null}
          >
            Redefinir Senha
          </Button>
        </Form.Item>
      </Form>
    </Flex>
  );
}
