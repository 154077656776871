import { UserType } from './UserType';

export const specialConditionsMap: Record<string, string> = {
  resident: 'Residente R1-R3',
  resident4: 'Residente R4',
};

export default function parseUsers(data: any[]): UserType[] {
  return data
    .map((item: any) => ({
      ...item,
      key: item.id,
      loginToken: item.loginToken || '',
      trialEndsAt: item.trialEndsAt || '',
      createdAt: item.createdAt || '',
      lastLoginAt: item.lastLoginAt || '',
      localizedTrialEndsAt: item.trialEndsAt
        ? new Date(item.trialEndsAt).toLocaleString('pt-BR').substring(0, 10)
        : '',
      localizedCreatedAt: item.createdAt
        ? new Date(item.createdAt).toLocaleString('pt-BR').replace(',', '')
        : '',
      localizedLastLoginAt: item.lastLoginAt
        ? new Date(item.lastLoginAt).toLocaleString('pt-BR').replace(',', '')
        : '',
      specialConditionName: specialConditionsMap[item.specialCondition] || null,
    }))
    .sort((a: UserType, b: UserType) => (a.name > b.name ? 1 : -1));
}
