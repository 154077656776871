import React, { useEffect, useState } from 'react';
import { Button, Flex, Form, message, Select, Tag } from 'antd';
import { FaCheck } from 'react-icons/fa6';
import api from '../../services/api';
import { handleApiError } from '../../services/functions';
import { useAuth } from '../../contexts/auth';

type UserEnterpriseProps = {
  userId: number;
  initialValue: number;
  setEnterprise: (enterpriseId: number, enterpriseName: string) => void;
};

type Option = {
  value: number;
  label: string;
};

export default function UserEnterprise({
  userId,
  initialValue,
  setEnterprise,
}: UserEnterpriseProps) {
  const { isAdmin } = useAuth();
  const [form] = Form.useForm();

  const [isLoading, setIsLoading] = useState(false);
  const [hasSucceeded, setHasSucceeded] = useState(false);
  const [options, setOptions] = useState([
    {
      value: initialValue,
      label: 'Carregando...',
    },
  ] as Option[]);

  async function getEnterprises() {
    if (!isAdmin()) {
      return;
    }

    setIsLoading(true);

    try {
      const response = await api.get('/admin/enterprises');
      const { data } = response.data;

      setOptions(
        data.map((e: any) => ({
          value: e.id,
          label: e.name,
        }))
      );
    } catch (e: any) {
      handleApiError(e);
    }

    setIsLoading(false);
  }

  useEffect(() => {
    getEnterprises();
  }, []);

  const submitForm = async (newEnterpriseId: string) => {
    setIsLoading(true);

    try {
      const { data } = await api.post(
        `/admin/users/${userId}/set-enterprise/${newEnterpriseId}`
      );

      message.success('Empresa configurada com sucesso');
      setEnterprise(data.enterpriseId, data.enterpriseName);
      setTimeout(() => {
        setHasSucceeded(false);
      }, 2000);
      setHasSucceeded(true);
    } catch (e) {
      handleApiError(e); // TODO: Tratar erro exibindo no formulário
    }

    setIsLoading(false);
  };

  const onFinish = (values: any) => {
    submitForm(values.enterpriseId);
  };

  return (
    <Flex justify='space-between' align='center'>
      <div>
        <strong style={{ fontSize: 'large' }}>Alterar empresa</strong>
        <br />
        Alterar a empresa a qual o usuário está vinculado
      </div>
      <Form layout='inline' form={form} onFinish={onFinish}>
        <Form.Item name='enterpriseId' initialValue={initialValue}>
          <Select style={{ width: 150 }} options={options} />
        </Form.Item>
        <Form.Item style={{ margin: 0 }}>
          <Button
            color='danger'
            variant='solid'
            htmlType='submit'
            loading={isLoading}
            icon={hasSucceeded ? <FaCheck /> : null}
          >
            Alterar empresa
          </Button>
        </Form.Item>
      </Form>
    </Flex>
  );
}
