/* eslint-disable prettier/prettier */
import React, { useEffect, useState } from 'react';
import {
  message,
  Table,
  Spin,
  Input,
  Statistic,
  Col,
  Row,
  Button,
  Flex,
  DatePicker,
} from 'antd';

import { useParams } from 'react-router-dom';
import dayjs, { Dayjs } from 'dayjs';
import DailyReports from './DailyReports';
import PieChart from './PieChart';
import ColumnChart from './ColumnChart';

const { RangePicker } = DatePicker;

interface DashboardResponse {
  items: any[];
  nextToken: string | null;
}

interface CountItem {
  name: string;
  count: number;
  percent?: number;
}

export default function Dashboard() {
  const [loading, setLoading] = useState(true);

  const params = useParams();
  const [userId, setUserId] = useState(params.userId ? params.userId : '');

  const [totalReports, setTotalReports] = useState(0);
  const [dailyMedia, setDailyMedia] = useState(0);
  const [dailyCounts, setDailyCounts] = useState<CountItem[]>([]);

  const [reportInconsistencyCounts, setReportInconsistencyCounts] = useState<
    CountItem[]
  >([]);
  const [previousExamComparisonCounts, setPreviousExamComparisonCounts] =
    useState<CountItem[]>([]);
  const [normalExamCounts, setNormalExamCounts] = useState<CountItem[]>([]);
  const [oncologicalFindingCounts, setOncologicalFindingCounts] = useState<
    CountItem[]
  >([]);
  const [examTypeCounts, setExamTypeCounts] = useState<CountItem[]>([]);
  const [diagnosticHypothesisCounts, setDiagnosticHypothesisCounts] = useState<
    CountItem[]
  >([]);
  const [suggestedExamDescriptionCounts, setSuggestedExamDescriptionCounts] =
    useState<CountItem[]>([]);
  const [technicalLimitationsCounts, setTechnicalLimitationsCounts] = useState<
    CountItem[]
  >([]);
  const [suggestedExamCounts, setSuggestedExamCounts] = useState<CountItem[]>(
    []
  );
  const [criticalFindingCounts, setCriticalFindingCounts] = useState<
    CountItem[]
  >([]);
  const [radsClassificationCounts, setRadsClassificationCounts] = useState<
    CountItem[]
  >([]);
  const [contrastUseCounts, setContrastUseCounts] = useState<CountItem[]>([]);
  const [
    criticalFindingDescriptionCounts,
    setCriticalFindingDescriptionCounts,
  ] = useState<CountItem[]>([]);
  const [
    oncologicalFindingDescriptionCounts,
    setOncologicalFindingDescriptionCounts,
  ] = useState<CountItem[]>([]);
  const [reportInconsistencyReasonCounts, setReportInconsistencyReasonCounts] =
    useState<CountItem[]>([]);

  const [messageApi, contextHolder] = message.useMessage();

  // Função para normalizar a string: remove espaços extras e converte para minúsculas
  const normalizeString = (str: string): string => {
    return str.trim().toLowerCase();
  };

  // Função genérica para contar os diferentes valores de um campo dentro de "data"
  const countFieldValues = <K extends keyof any['data']>(
    data: any[],
    field: K
  ): Record<string, number> => {
    return data.reduce((acc, item) => {
      try {
        const value = item.data[field];
        const key =
          value === null || value === undefined ? 'null' : String(value);
        acc[key] = (acc[key] || 0) + 1;
        return acc;
      } catch (error) {
        console.error('Erro ao contar valores:', error);
        return acc;
      }
    }, {} as Record<string, number>);
  };

  // Função auxiliar para adicionar o percentual em cada item
  const addPercent = (arr: CountItem[]): CountItem[] => {
    const total = arr.reduce((acc, item) => acc + item.count, 0);
    return arr.map((item) => ({
      ...item,
      percent: total ? (item.count / total) * 100 : 0,
    }));
  };

  // Função para contar os itens por dia, baseado no campo createAt
  const countItemsByDay = (data: any[]): Record<string, number> => {
    return data.reduce((acc, item) => {
      // Formata a data como yyyy-mm-dd
      const dateStr = new Date(item.createAt).toISOString().split('T')[0];
      acc[dateStr] = (acc[dateStr] || 0) + 1;
      return acc;
    }, {} as Record<string, number>);
  };

  const handleData = (countsObj: Record<string, number>): CountItem[] => {
    return addPercent(
      Object.entries(countsObj)
        .map(([name, count]) => ({ name, count }))
        .filter((item) => item.name !== 'null')
        .sort((a, b) => b.count - a.count)
    );
  };

  const [dateRange, setDateRange] = useState<[Dayjs, Dayjs]>([
    dayjs().subtract(7, 'days'),
    dayjs(),
  ]);

  // Função recursiva para buscar páginas de dados
  const fetchPage = async (
    nextToken: string | null,
    allItems: any[] = [],
    startDate: Dayjs | null = null,
    endDate: Dayjs | null = null
  ): Promise<any[]> => {
    const startDateMs = startDate ? startDate.startOf('day').valueOf() : null;
    const endDateMs = endDate ? endDate.endOf('day').valueOf() : null;

    const response = await fetch(
      `${process.env.REACT_APP_API_DASHBOARD_URL}/dashboard/${
        userId || 'tag/default'
      }?limit=1000&startDate=${startDateMs}&endDate=${endDateMs}&nextToken=${
        nextToken || ''
      }`,
      {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${process.env.REACT_APP_API_DASHBOARD_KEY}`,
        },
        method: 'GET',
      }
    );

    if (!response.ok) {
      messageApi.open({
        type: 'error',
        content: 'Erro ao carregar dados.',
      });
      throw new Error('Erro ao carregar dados.');
    }

    const { items, nextToken: newToken } =
      (await response.json()) as DashboardResponse;
    const updatedItems = [...allItems, ...items];

    if (newToken) {
      return fetchPage(newToken, updatedItems, startDate, endDate);
    }
    return updatedItems;
  };

  // Função que busca todos os dados, extrai as contagens, ordena do maior para o menor, descarta itens com name "null" e adiciona o percentual
  const fetchAll = async (
    startDate: Dayjs | null = null,
    endDate: Dayjs | null = null
  ) => {
    setLoading(true);
    setTotalReports(0);
    setDailyCounts([]);
    setDailyMedia(0);
    setReportInconsistencyCounts([]);
    setPreviousExamComparisonCounts([]);
    setNormalExamCounts([]);
    setOncologicalFindingCounts([]);
    setExamTypeCounts([]);
    setDiagnosticHypothesisCounts([]);
    setSuggestedExamDescriptionCounts([]);
    setTechnicalLimitationsCounts([]);
    setSuggestedExamCounts([]);
    setCriticalFindingCounts([]);
    setRadsClassificationCounts([]);
    setContrastUseCounts([]);
    setCriticalFindingDescriptionCounts([]);
    setOncologicalFindingDescriptionCounts([]);
    setReportInconsistencyReasonCounts([]);

    try {
      const all = await fetchPage(null, [], startDate, endDate);
      const allItems = all.sort((a, b) => b.createAt - a.createAt);
      setTotalReports(allItems.length);

      // Contagem dos itens por dia (baseado no createAt)
      const dailyCountsObj = countItemsByDay(allItems);
      const dailyCountsArr: CountItem[] = handleData(dailyCountsObj);
      setDailyCounts(
        dailyCountsArr.sort(
          (a, b) => dayjs(a.name).unix() - dayjs(b.name).unix()
        )
      );

      const t = Number(allItems.length / dailyCountsArr.length);
      setDailyMedia(t);

      const reportInconsistencyCountsArr: CountItem[] = handleData(
        countFieldValues(allItems, 'reportInconsistency')
      );
      setReportInconsistencyCounts(reportInconsistencyCountsArr);

      const previousExamComparisonArr: CountItem[] = handleData(
        countFieldValues(allItems, 'previousExamComparison')
      );
      setPreviousExamComparisonCounts(previousExamComparisonArr);

      const normalExamArr: CountItem[] = handleData(
        countFieldValues(allItems, 'normalExam')
      );
      setNormalExamCounts(normalExamArr);

      const oncologicalFindingArr: CountItem[] = handleData(
        countFieldValues(allItems, 'oncologicalFinding')
      );
      setOncologicalFindingCounts(oncologicalFindingArr);

      const examTypeArr: CountItem[] = handleData(
        countFieldValues(allItems, 'examType')
      );
      setExamTypeCounts(examTypeArr);

      const diagnosticHypothesisArr: CountItem[] = handleData(
        countFieldValues(allItems, 'diagnosticHypothesis')
      );
      setDiagnosticHypothesisCounts(diagnosticHypothesisArr);

      const suggestedExamDescriptionArr: CountItem[] = handleData(
        countFieldValues(allItems, 'suggestedExamDescription')
      );
      setSuggestedExamDescriptionCounts(suggestedExamDescriptionArr);

      const technicalLimitationsArr: CountItem[] = handleData(
        countFieldValues(allItems, 'technicalLimitations')
      );
      setTechnicalLimitationsCounts(technicalLimitationsArr);

      const suggestedExamArr: CountItem[] = handleData(
        countFieldValues(allItems, 'suggestedExam')
      );
      setSuggestedExamCounts(suggestedExamArr);

      const criticalFindingArr: CountItem[] = handleData(
        countFieldValues(allItems, 'criticalFinding')
      );
      setCriticalFindingCounts(criticalFindingArr);

      const radsClassificationArr: CountItem[] = handleData(
        countFieldValues(allItems, 'radsClassification')
      );
      setRadsClassificationCounts(radsClassificationArr);

      const contrastUseArr: CountItem[] = handleData(
        countFieldValues(allItems, 'contrastUse')
      );
      setContrastUseCounts(contrastUseArr);

      const criticalFindingDescriptionArr: CountItem[] = handleData(
        countFieldValues(allItems, 'criticalFindingDescription')
      );
      setCriticalFindingDescriptionCounts(criticalFindingDescriptionArr);

      const oncologicalFindingDescriptionArr: CountItem[] = handleData(
        countFieldValues(allItems, 'oncologicalFindingDescription')
      );
      setOncologicalFindingDescriptionCounts(oncologicalFindingDescriptionArr);

      const reportInconsistencyReasonArr: CountItem[] = handleData(
        countFieldValues(allItems, 'reportInconsistencyReason')
      );
      setReportInconsistencyReasonCounts(reportInconsistencyReasonArr);
    } catch (error) {
      console.error('Erro ao buscar dados:', error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchAll(dateRange[0], dateRange[1]);
  }, []);

  // Definição das colunas para as tabelas (incluindo a coluna de percentual)
  const columns = [
    {
      title: 'REFERÊNCIA',
      dataIndex: 'name',
      key: 'name',
      render: (name: any) => (
        <div style={{ fontSize: 16, textTransform: 'capitalize' }}>{name}</div>
      ),
      width: 300,
    },
    {
      title: 'TOTAL',
      dataIndex: 'count',
      key: 'count',
      render: (count: any) => <strong style={{ fontSize: 16 }}>{count}</strong>,
      width: 60,
    },
    {
      title: '%',
      dataIndex: 'percent',
      key: 'percent',
      render: (percent: number) => (
        <div style={{ fontSize: 16 }}>{percent.toFixed(2)}%</div>
      ),
      width: 60,
    },
  ];

  return (
    <div>
      {contextHolder}
      <Flex gap='large' align='flex-end' justify='space-between'>
        <h1>Dashboard</h1>
        <div>
          <RangePicker
            value={dateRange}
            onChange={(dates) => {
              setDateRange([dates?.[0] as Dayjs, dates?.[1] as Dayjs]);
              fetchAll(dates?.[0] as Dayjs, dates?.[1] as Dayjs);
            }}
          />
          &nbsp;&nbsp;
          <Input
            style={{ width: 150, marginBottom: 16 }}
            placeholder='ID do Usuário'
            value={userId}
            onChange={(e) => {
              setUserId(e.target.value);
            }}
          />
          &nbsp;&nbsp;
          <Button
            disabled={!userId}
            type='primary'
            onClick={() => fetchAll(dateRange[0], dateRange[1])}
          >
            ATUALIZAR
          </Button>
          &nbsp;&nbsp;
          <Button
            type='primary'
            onClick={() => {
              setUserId('');
              fetchAll(dateRange[0], dateRange[1]);
            }}
          >
            TODOS
          </Button>
        </div>
      </Flex>

      <Spin
        tip='Carregando, pode levar alguns minutos...'
        size='large'
        spinning={loading}
      >
        <Row gutter={4}>
          <Col span={4}>
            <Statistic title='Total de laudos' value={totalReports} />
          </Col>
          <Col span={4}>
            <Statistic title='Média por dia' value={dailyMedia} precision={0} />
          </Col>
        </Row>

        <br />
        <br />

        {dailyCounts.length > 1 && (
          <div>
            <div
              style={{
                width: '99%',
                border: '1px solid #ccc',
                borderRadius: 8,
                padding: '16px 16px 16px 16px',
              }}
            >
              <h2 style={{ color: '#0ea5e9', marginTop: 5, marginBottom: 5 }}>
                Laudos por dia
              </h2>
              <DailyReports data={dailyCounts} />
            </div>

            <br />
            <br />
          </div>
        )}

        <div
          style={{
            width: '99%',
            border: '1px solid #ccc',
            borderRadius: 8,
            padding: '16px 16px 0px 16px',
          }}
        >
          <h2 style={{ color: '#0ea5e9', marginTop: 5, marginBottom: 5 }}>
            Tipo de exame realizado ({examTypeCounts.length})
          </h2>
          <div>Indica o tipo de exame realizado.</div>
          <br />
          <ColumnChart data={examTypeCounts} slider={[0, 0.25]} />
          <Table
            dataSource={examTypeCounts}
            columns={columns}
            rowKey='name'
            pagination={false}
            size='small'
            scroll={{ x: 'max-content', y: 250 }}
          />
        </div>

        <br />
        <br />

        <Row gutter={16} justify='space-between' align='top'>
          <Col span={12}>
            <div
              style={{
                border: '1px solid #ccc',
                borderRadius: 8,
                padding: '16px 16px 16px 16px',
              }}
            >
              <h2 style={{ color: '#0ea5e9', marginTop: 5, marginBottom: 5 }}>
                Classificação RADS ({radsClassificationCounts.length})
              </h2>
              <div>
                Classificação RADS mencionada no laudo (BI-RADS, PI-RADS,
                LI-RADS etc.)
              </div>
              <br />
              <ColumnChart data={radsClassificationCounts} slider={[0, 0.75]} />
              <Table
                dataSource={radsClassificationCounts}
                columns={columns}
                rowKey='name'
                pagination={false}
                size='small'
                scroll={{ x: 'max-content', y: 250 }}
              />
            </div>
          </Col>
          <Col span={12}>
            <div
              style={{
                border: '1px solid #ccc',
                borderRadius: 8,
                padding: '16px 16px 16px 16px',
              }}
            >
              <h2 style={{ color: '#0ea5e9', marginTop: 5, marginBottom: 5 }}>
                Uso de contraste ({contrastUseCounts.length})
              </h2>
              <div>
                Especifica se o exame foi realizado com contraste, sem contraste
                ou se não há menção de contraste no laudo.
              </div>
              <br />
              <PieChart data={contrastUseCounts} />
              <Table
                dataSource={contrastUseCounts}
                columns={columns}
                rowKey='name'
                pagination={false}
                size='small'
                scroll={{ x: 'max-content', y: 250 }}
              />
            </div>
          </Col>
        </Row>

        <br />
        <br />

        <Row gutter={16} justify='space-between' align='top'>
          <Col span={12}>
            <div
              style={{
                border: '1px solid #ccc',
                borderRadius: 8,
                padding: '16px 16px 16px 16px',
              }}
            >
              <h2 style={{ color: '#0ea5e9', marginTop: 5, marginBottom: 5 }}>
                Exame considerado normal ({normalExamCounts.length})
              </h2>
              <div>
                Indica se o exame é considerado normal. true se a conclusão for
                algo como (Não há alterações significativas) ou sem achados
                anormais. false se houver quaisquer anormalidades.
              </div>
              <br />
              <PieChart data={normalExamCounts} />
              <Table
                dataSource={normalExamCounts}
                columns={columns}
                rowKey='name'
                pagination={false}
                size='small'
                scroll={{ x: 'max-content', y: 250 }}
              />
            </div>
          </Col>
          <Col span={12}>
            <div
              style={{
                border: '1px solid #ccc',
                borderRadius: 8,
                padding: '16px 16px 16px 16px',
              }}
            >
              <h2 style={{ color: '#0ea5e9', marginTop: 5, marginBottom: 5 }}>
                Comparação com exame anterior (
                {previousExamComparisonCounts.length})
              </h2>
              <div>
                Indica se o laudo faz menção comparativa a algum exame anterior.
              </div>
              <br />
              <PieChart data={previousExamComparisonCounts} />
              <Table
                dataSource={previousExamComparisonCounts}
                columns={columns}
                rowKey='name'
                pagination={false}
                size='small'
                scroll={{ x: 'max-content', y: 250 }}
              />
            </div>
          </Col>
        </Row>

        <br />
        <br />

        <div
          style={{
            width: '100%',
            border: '1px solid #ccc',
            borderRadius: 8,
            padding: '16px 16px 0px 16px',
          }}
        >
          <h2 style={{ color: '#0ea5e9', marginTop: 5, marginBottom: 5 }}>
            Hipótese diagnóstica ({diagnosticHypothesisCounts.length})
          </h2>
          <div>
            Hipótese diagnóstica ou conclusão do exame, conforme relatada no
            laudo.
          </div>
          <Table
            dataSource={diagnosticHypothesisCounts}
            columns={columns}
            rowKey='name'
            pagination={false}
            size='small'
            scroll={{ x: 'max-content', y: 250 }}
          />
        </div>

        <br />
        <br />

        <div
          style={{
            width: '100%',
            border: '1px solid #ccc',
            borderRadius: 8,
            padding: '16px 16px 0px 16px',
          }}
        >
          <h2 style={{ color: '#0ea5e9', marginTop: 5, marginBottom: 5 }}>
            Limitações técnicas ({technicalLimitationsCounts.length})
          </h2>
          <div>
            Aponta limitações técnicas que possam ter prejudicado a qualidade ou
            a interpretação do exame (por exemplo, movimentação do paciente,
            artefatos, janela de aquisição deficiente).
          </div>
          <Table
            dataSource={technicalLimitationsCounts}
            columns={columns}
            rowKey='name'
            pagination={false}
            size='small'
            scroll={{ x: 'max-content', y: 250 }}
          />
        </div>

        <br />
        <br />

        <div
          style={{
            width: '100%',
            border: '1px solid #ccc',
            borderRadius: 8,
            padding: '16px 16px 0px 16px',
          }}
        >
          <Row gutter={16} justify='space-around' align='top'>
            <Col span={8}>
              <h2 style={{ color: '#0ea5e9', marginTop: 5, marginBottom: 0 }}>
                Exame complementar
              </h2>
              <div>Indica se o laudo sugere algum exame complementar.</div>
              <br />
              <br />
              <PieChart data={suggestedExamCounts} />
            </Col>
            <Col span={16}>
              <h2 style={{ color: '#0ea5e9', marginTop: 5, marginBottom: 0 }}>
                Descrição exame complementar (
                {suggestedExamDescriptionCounts.length})
              </h2>
              <div>
                Detalha qual exame complementar foi sugerido, se houver.
              </div>
              <Table
                dataSource={suggestedExamDescriptionCounts}
                columns={columns}
                rowKey='name'
                pagination={false}
                size='small'
                scroll={{ x: 'max-content', y: 450 }}
              />
            </Col>
          </Row>
        </div>

        <br />
        <br />

        <div
          style={{
            width: '100%',
            border: '1px solid #ccc',
            borderRadius: 8,
            padding: '16px 16px 0px 16px',
          }}
        >
          <Row gutter={16} justify='space-around' align='top'>
            <Col span={8}>
              <h2 style={{ color: '#0ea5e9', marginTop: 5, marginBottom: 0 }}>
                Achado crítico
              </h2>
              <div>Indica se há algum achado crítico (urgente) no laudo.</div>
              <br />
              <br />
              <PieChart data={criticalFindingCounts} />
            </Col>
            <Col span={16}>
              <h2 style={{ color: '#0ea5e9', marginTop: 5, marginBottom: 0 }}>
                Descrição do achado crítico (
                {criticalFindingDescriptionCounts.length})
              </h2>
              <div>Descreve sucintamente o achado crítico, caso exista.</div>
              <Table
                dataSource={criticalFindingDescriptionCounts}
                columns={columns}
                rowKey='name'
                pagination={false}
                size='small'
                scroll={{ x: 'max-content', y: 450 }}
              />
            </Col>
          </Row>
        </div>

        <br />
        <br />

        <div
          style={{
            width: '100%',
            border: '1px solid #ccc',
            borderRadius: 8,
            padding: '16px 16px 0px 16px',
          }}
        >
          <Row gutter={16} justify='space-around' align='top'>
            <Col span={8}>
              <h2 style={{ color: '#0ea5e9', marginTop: 5, marginBottom: 0 }}>
                Achado oncológico
              </h2>
              <div>
                Indica se há achado que sugira lesão oncológica (maligna).
              </div>
              <br />
              <br />
              <PieChart data={oncologicalFindingCounts} />
            </Col>
            <Col span={16}>
              <h2 style={{ color: '#0ea5e9', marginTop: 5, marginBottom: 0 }}>
                Descrição de achados oncológicos (
                {oncologicalFindingDescriptionCounts.length})
              </h2>
              <div>
                Descreve o achado oncológico, se presente. Exemplo: Lesão
                expansiva compatível com metástase hepática.
              </div>
              <Table
                dataSource={oncologicalFindingDescriptionCounts}
                columns={columns}
                rowKey='name'
                pagination={false}
                size='small'
                scroll={{ x: 'max-content', y: 450 }}
              />
            </Col>
          </Row>
        </div>

        <br />
        <br />

        <div
          style={{
            width: '100%',
            border: '1px solid #ccc',
            borderRadius: 8,
            padding: '16px 16px 0px 16px',
          }}
        >
          <Row gutter={16} justify='space-around' align='top'>
            <Col span={8}>
              <h2 style={{ color: '#0ea5e9', marginTop: 5, marginBottom: 0 }}>
                Inconsistência no laudo
              </h2>
              <div>
                Indica se há inconsistência no laudo (por exemplo, descreve
                realce por contraste mas no campo contrastUse consta: Without
                contrast). true se foi encontrada alguma contradição no texto.
                false caso contrário.
              </div>
              <br />
              <br />
              <PieChart data={reportInconsistencyCounts} />
            </Col>
            <Col span={16}>
              <h2 style={{ color: '#0ea5e9', marginTop: 5, marginBottom: 0 }}>
                Descrição das inconsistência (
                {reportInconsistencyReasonCounts.length})
              </h2>
              <div>
                Explica o motivo da inconsistência, se houver. Exemplo: Há
                menção de realce de contraste, mas contrastUse ficou como
                Without contrast.
              </div>
              <Table
                dataSource={reportInconsistencyReasonCounts}
                columns={columns}
                rowKey='name'
                pagination={false}
                size='small'
                scroll={{ x: 'max-content', y: 450 }}
              />
            </Col>
          </Row>
        </div>
      </Spin>
    </div>
  );
}
