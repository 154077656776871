/* eslint-disable jsx-a11y/control-has-associated-label */
/* eslint-disable no-nested-ternary */
/* eslint-disable prettier/prettier */
import React, { useEffect, useState } from 'react';
import {
  Button,
  Table,
  TableColumnsType,
  Popover,
  Select,
  Spin,
  Col,
  Row,
  Card,
} from 'antd';
import moment from 'moment';
import { CSVLink } from 'react-csv';
import { useAuth } from '../../contexts/auth';
import './styles.css';
import 'moment/locale/pt-br';
import Detalles from './Detalles';

export default function LogsTag() {
  const { isAdmin } = useAuth();

  const tags = ['AI Assistant', 'Speech', 'Tutor', 'Scan'];

  const [isLoading, setIsLoading] = useState(false);
  const [limit, setLimit] = useState(50);
  const [tag, setTag] = useState(tags[0]);
  const [logs, setLogs] = useState([] as any);
  const [nextToken, setNextToken] = useState('');

  const [showLog, setShowLog] = useState(false);
  const [log, setLog] = useState({} as any);

  const columns: TableColumnsType<any> = [];

  columns.push({
    title: '#',
    key: 'num',
    render: (_, record: any) => (
      <Button
        size='small'
        type={record.num === log.num ? 'primary' : 'default'}
        onClick={() => {
          setLog(record);
          setShowLog(true);
        }}
      >
        <strong>{record.num}</strong>
      </Button>
    ),
  });

  columns.push({
    title: 'DATA',
    dataIndex: 'createAt',
    key: 'createAt',
    fixed: 'left',
    // sorter: (a: any, b: any) => (a.createAt > b.createAt ? 1 : -1),
    render: (createAt) => (
      <div>
        {moment.unix(createAt / 1000).format('DD/MM/YYYY')} <br />
        {moment.unix(createAt / 1000).format('HH:mm:ss')}
      </div>
    ),
  });

  columns.push({
    title: 'USUÁRIO',
    key: 'userId',
    fixed: 'left',
    render: (_, record) => (
      <div>
        <strong>
          <a href={`/logs-user/${record.userId}`}>{record.userEmail}</a>
        </strong>
        <br />
        <span>ID: {record.userId}</span>
      </div>
    ),
  });

  columns.push({
    title: 'STATUS',
    key: 'status',
    fixed: 'right',
    width: 80,
    render: (_, record) => {
      if (record.processing) {
        return <Spin />;
      }
      if (record.errorDify) {
        return (
          <Popover
            placement='left'
            content={
              <div
                style={{
                  maxWidth: 640,
                  maxHeight: 320,
                  overflowY: 'auto',
                }}
              >
                {record.errorDify}
              </div>
            }
          >
            <span style={{ color: 'red' }}>
              <strong>DIFY</strong>
              <br />
              <small>ERROR</small>
            </span>
          </Popover>
        );
      }
      if (record.errorTranscript) {
        return <span style={{ color: 'red' }}>{record.errorTranscript}</span>;
      }
      if (record.error && record.error !== '{}') {
        return <strong style={{ color: 'red' }}>{record.error}</strong>;
      }
      if (record.responseStatus === 408) {
        return (
          <span style={{ color: 'red' }}>
            <strong>408</strong>
            <br />
            <small>TIMEOUT</small>
          </span>
        );
      }
      if (record.responseStatus === 401) {
        return (
          <span style={{ color: 'red' }}>
            <strong>401</strong>
            <br />
            <small>TOKEN</small>
          </span>
        );
      }
      if (record.responseStatus === 502) {
        return (
          <span style={{ color: 'red' }}>
            <strong>502</strong>
            <br />
            <small>Bad Gateway</small>
          </span>
        );
      }
      if (record.responseStatus === 503) {
        return (
          <span style={{ color: 'red' }}>
            <strong>503</strong>
            <br />
            <small>Unavailable</small>
          </span>
        );
      }
      if (record.responseStatus) {
        return (
          <strong style={{ color: 'red' }}>{record.responseStatus}</strong>
        );
      }
      if (record.durationTotal && !record.result) {
        return (
          <strong style={{ color: 'red' }}>
            <small>SEM</small>
            <br />
            <small>RESPOSTA</small>
          </strong>
        );
      }
      return <span>OK</span>;
    },
  });

  const fetchLogs = async (nt?: string) => {
    if (!isAdmin()) return;
    if (!nt) setLogs([]);
    setIsLoading(true);

    try {
      const encodedTag = encodeURIComponent(tag);
      const response = await fetch(
        `${process.env.REACT_APP_API_LOGS_URL}/logs/tag/${encodedTag}/?limit=${limit}&order=desc&nextToken=${nt}`,
        {
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${process.env.REACT_APP_API_LOGS_KEY}`,
          },
          method: 'GET',
        }
      );

      if (!response.ok) {
        throw new Error('Erro ao buscar logs');
      }

      const l = await response.json();

      const logsWithAudio = await Promise.all(
        l.items.map(async (item: any, index: number) => {
          let audioUrl = null;
          const num = index + logs.length + 1;
          if (item.fileKey) {
            try {
              const audioResponse = await fetch(
                `${process.env.REACT_APP_API_LOGS_URL}/audio/play/${item.fileKey}`,
                {
                  headers: {
                    Authorization: `Bearer ${process.env.REACT_APP_API_LOGS_KEY}`,
                  },
                  method: 'GET',
                }
              );
              if (audioResponse.ok) {
                const { url } = await audioResponse.json();
                audioUrl = url;
              }
            } catch (error) {
              console.error(
                `Erro ao buscar áudio para ${item.fileKey}:`,
                error
              );
            }
          }
          return { ...item, audioUrl, num };
        })
      );

      if (nt) {
        setLogs((prevLogs: any) => [...prevLogs, ...logsWithAudio]);
      } else {
        setLogs(logsWithAudio);
      }

      setNextToken(l.nextToken || null);
    } catch (error) {
      console.error('Erro ao buscar logs:', error);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    setLogs([]);
    fetchLogs('');

    return () => {
      setLogs([]);
    };
  }, []);

  return isAdmin() ? (
    <div>
      <Select
        style={{ width: 75, marginBottom: 16 }}
        placeholder={limit}
        options={[
          { value: 25, label: 25 },
          { value: 50, label: 50 },
          { value: 75, label: 75 },
          { value: 100, label: 100 },
          { value: 250, label: 250 },
        ]}
        onChange={(value) => {
          setLimit(value);
        }}
      />
      &nbsp;&nbsp;
      <Select
        style={{ width: 200, marginBottom: 16 }}
        placeholder={tag}
        filterOption={(input, option) =>
          (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
        }
        options={[
          { value: tags[0], label: tags[0] },
          { value: tags[1], label: 'AI Speech' },
          { value: tags[2], label: tags[2] },
          { value: tags[3], label: tags[3] },
          // { value: '', label: 'None' },
        ]}
        onChange={(value) => {
          setShowLog(false);
          setLog([]);
          setNextToken('');
          setTag(value);
        }}
      />
      &nbsp;&nbsp;
      <Button type='primary' onClick={() => fetchLogs('')}>
        ATUALIZAR
      </Button>
      &nbsp;&nbsp;&nbsp;
      <CSVLink data={logs}>EXPORTAR</CSVLink>
      <Row>
        <Col span={7}>
          <Table
            loading={isLoading}
            size='small'
            dataSource={logs}
            columns={columns}
            pagination={false}
            scroll={{
              x: 'max-content',
              y: document.documentElement.scrollHeight - 250,
            }}
            rowClassName={(record) => {
              if (record.responseStatus === 408) return 'highlight-red';
              if (record.responseStatus === 503) return 'highlight-red';
              if (record.responseStatus === 502) return 'highlight-red';
              if (record.responseStatus) return 'highlight-orange';
              if (record.error && record.error !== '{}') return 'highlight-red';
              if (record.durationTotal && !record.result)
                return 'highlight-red';
              if (record.errorTranscript) return 'highlight-orange';
              return '';
            }}
          />
          {nextToken && (
            <Button
              type='primary'
              style={{ marginTop: 16, alignItems: 'center' }}
              onClick={() => fetchLogs(nextToken)}
            >
              Carregar mais
            </Button>
          )}
        </Col>
        <Col span={17}>
          {showLog && (
            <Card style={{ marginLeft: 10, backgroundColor: '#f5f5f5' }}>
              <Detalles log={log} />
            </Card>
          )}
        </Col>
      </Row>
    </div>
  ) : (
    <div />
  );
}
