import React, { useEffect, useState } from 'react';
import { Button, Flex, Form, message, Select, Tag } from 'antd';
import { FaCheck } from 'react-icons/fa6';
import api from '../../services/api';
import { handleApiError } from '../../services/functions';
import { useAuth } from '../../contexts/auth';
import { specialConditionsMap } from './parseUsers';

type UserSpecialConditionProps = {
  userId: number;
  initialValue: string | null;
  setSpecialCondition: (
    specialCondition: string | null,
    specialConditionName: string | null,
    specialConditionSince: string | null
  ) => void;
};

type Option = {
  value: string;
  label: string;
};

export default function UserSpecialCondition({
  userId,
  initialValue,
  setSpecialCondition,
}: UserSpecialConditionProps) {
  const [form] = Form.useForm();

  const [isLoading, setIsLoading] = useState(false);
  const [hasSucceeded, setHasSucceeded] = useState(false);

  const options = ['', 'resident', 'resident4'].map((item: string) => ({
    value: item,
    label: specialConditionsMap[item] || 'Nenhuma',
  }));

  const submitForm = async (newSpecialCondition: string) => {
    setIsLoading(true);

    try {
      const { data } = await api.post(
        `/admin/users/${userId}/set-special-condition/${
          newSpecialCondition || 'null'
        }`
      );

      message.success('Condição especial configurada com sucesso');
      setSpecialCondition(
        data.specialCondition,
        specialConditionsMap[data.specialCondition],
        data.specialConditionSince
      );
      setTimeout(() => {
        setHasSucceeded(false);
      }, 2000);
      setHasSucceeded(true);
    } catch (e) {
      handleApiError(e); // TODO: Tratar erro exibindo no formulário
    }

    setIsLoading(false);
  };

  const onFinish = (values: any) => {
    submitForm(values.specialCondition);
  };

  return (
    <Flex justify='space-between' align='center'>
      <div>
        <strong style={{ fontSize: 'large' }}>
          Condição Especial&nbsp;
          <Tag color='blue'>NOVO !! </Tag>
        </strong>
        <br />
        Configure uma condição especial
      </div>
      <Form layout='inline' form={form} onFinish={onFinish}>
        <Form.Item name='specialCondition' initialValue={initialValue}>
          <Select style={{ width: 150 }} options={options} />
        </Form.Item>
        <Form.Item style={{ margin: 0 }}>
          <Button
            color='danger'
            variant='solid'
            htmlType='submit'
            loading={isLoading}
            icon={hasSucceeded ? <FaCheck /> : null}
          >
            Salvar condição especial
          </Button>
        </Form.Item>
      </Form>
    </Flex>
  );
}
