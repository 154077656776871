import React, { useState } from 'react';
import { Button } from 'antd';
import { PlayCircleOutlined } from '@ant-design/icons';

let activeAudio = null as any;
interface AudioPlayerProps {
  audioUrl: string;
}

function AudioPlayer({ audioUrl }: AudioPlayerProps) {
  const [isPlaying, setIsPlaying] = useState(false);

  const playAudio = () => {
    if (activeAudio) {
      activeAudio.pause();
      activeAudio.currentTime = 0;
      activeAudio = null;
    } else {
      const audio = new Audio(audioUrl);
      audio.crossOrigin = 'anonymous';

      audio
        .play()
        .then(() => {
          activeAudio = audio;
          setIsPlaying(true);
        })
        .catch((error) => {
          console.error('Erro ao reproduzir áudio:', error);
        });

      audio.onpause = () => {
        setIsPlaying(false);
      };

      audio.onended = () => {
        setIsPlaying(false);
        activeAudio = null;
      };
    }
  };

  return (
    <Button
      onClick={playAudio}
      type={isPlaying ? 'primary' : 'default'}
      danger={isPlaying}
      shape='circle'
      icon={<PlayCircleOutlined />}
    />
  );
}

export default AudioPlayer;
