/* eslint-disable prettier/prettier */
import React, { useState, useRef } from 'react';

import { Editor } from '@tinymce/tinymce-react';

import {
  Button,
  Form,
  Input,
  Select,
  Space,
  message,
  Tabs,
  Spin,
  Table,
  TableColumnsType,
  Popover,
} from 'antd';

import type { TabsProps } from 'antd';
import moment from 'moment';

const { Option } = Select;

export default function Notifications() {
  const [loading, setLoading] = useState(true);
  const [messages, setMessages] = useState([] as any);

  const [form] = Form.useForm();
  const typeValue = Form.useWatch('type', form);
  const [messageApi, contextHolder] = message.useMessage();

  const editorRef = useRef(null) as any;

  const fetchMessages = async () => {
    setLoading(true);
    const response = await fetch(
      `${process.env.REACT_APP_API_NOTIFICATIONS_URL}/notifications/all`,
      {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${process.env.REACT_APP_API_NOTIFICATIONS_KEY}`,
        },
        method: 'GET',
      }
    );

    if (!response.ok) {
      throw new Error(`Erro: ${response.status} - ${response.statusText}`);
    }

    const { items } = await response.json();
    setMessages(items.sort((a: any, b: any) => b.createdAt - a.createdAt));
    setLoading(false);
  };

  const onFinish = async (values: any) => {
    const content = editorRef.current.getContent();

    if (values.type !== 'ALL' && !values.recipient) {
      messageApi.open({
        type: 'error',
        content: 'Informe o email do usuário.',
      });
      return;
    }

    if (!values.subject || !content) {
      messageApi.open({
        type: 'error',
        content: 'Informe o assunto e a mensagem.',
      });
      return;
    }

    const response = await fetch(
      `${process.env.REACT_APP_API_NOTIFICATIONS_URL}/notifications`,
      {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${process.env.REACT_APP_API_NOTIFICATIONS_KEY}`,
        },
        method: 'POST',
        body: JSON.stringify({
          recipient: values.type === 'ALL' ? 'ALL' : values.recipient,
          subject: values.subject,
          content,
        }),
      }
    );

    if (!response.ok) {
      messageApi.open({
        type: 'error',
        content: 'Erro ao enviar notificação.',
      });
      throw new Error('Erro ao salvar notifications');
    }

    form.resetFields();
    editorRef.current.setContent('');
    messageApi.open({
      type: 'success',
      content: 'Envio realizado com sucesso!',
    });

    const ws = new WebSocket(`${process.env.REACT_APP_WEBSOCKET}`);

    ws.onopen = (event) => {
      ws.send(
        JSON.stringify({
          action: 'sendMessage',
          message: {
            type: 'notification',
            recipient: values.type === 'ALL' ? 'ALL' : values.recipient,
          },
        })
      );
      ws.close();
    };
  };

  const onReset = () => {
    form.resetFields();
  };

  const onChange = (key: string) => {
    if (key === '2') {
      fetchMessages();
    }
  };

  function sanitize(text: string) {
    return text
      .replace(/\n/g, '<br/>')
      .replace(/\*\*(.*?)\*\*/g, '<strong>$1</strong>')
      .replace(/(^[A-ZÀ-ÖØ-Ýa-zà-öø-ÿ ]+:\s)/gm, '<strong>$1</strong>');
  }

  const handleDelete = async (record: any) => {
    const response = await fetch(
      `${process.env.REACT_APP_API_NOTIFICATIONS_URL}/notifications/${record.id}`,
      {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${process.env.REACT_APP_API_NOTIFICATIONS_KEY}`,
        },
        method: 'DELETE',
      }
    );

    if (!response.ok) {
      messageApi.open({
        type: 'error',
        content: 'Erro ao apagar notificação.',
      });
      throw new Error('Erro ao apagar notifications');
    }

    messageApi.open({
      type: 'success',
      content: 'Notificação apagada com sucesso!',
    });

    fetchMessages();
  };

  const columns: TableColumnsType<any> = [
    {
      title: 'DATA E HORA',
      dataIndex: 'createAt',
      key: 'createAt',
      width: 130,
      render: (createAt) => (
        <div>
          {moment.unix(createAt / 1000).format('DD/MM/YYYY')} <br />
          {moment.unix(createAt / 1000).format('HH:mm:ss')}
        </div>
      ),
    },
    {
      title: 'PARA',
      dataIndex: 'recipient',
      key: 'recipient',
      width: 250,
    },
    {
      title: 'ASSUNTO',
      dataIndex: 'subject',
      key: 'subject',
      width: 250,
    },
    {
      title: 'MENSAGEM',
      key: 'content',
      render: (_, record) =>
        record.content ? (
          <Popover
            placement='bottom'
            content={
              <div
                style={{
                  maxWidth: 640,
                  maxHeight: 320,
                  overflowY: 'auto',
                }}
                dangerouslySetInnerHTML={{
                  __html: sanitize(record.content),
                }}
              />
            }
          >
            <div>{record.content.slice(0, 25)}...</div>
          </Popover>
        ) : (
          <span />
        ),
    },
    {
      title: 'Atalhos',
      dataIndex: '',
      key: 'links',
      width: 120,
      render: (_, record) => (
        <Button danger onClick={() => handleDelete(record)}>
          Apagar
        </Button>
      ),
    },
  ];

  const items: TabsProps['items'] = [
    {
      key: '1',
      label: 'ENVIAR',
      children: (
        <Form
          layout='vertical'
          form={form}
          name='control-hooks'
          onFinish={onFinish}
          style={{ maxWidth: 800, marginTop: 20 }}
        >
          <Form.Item
            layout='horizontal'
            name='type'
            label='Enviar para'
            rules={[{ required: true, message: 'Campo obrigatório!' }]}
          >
            <Select placeholder='Selecione' allowClear>
              <Option value='ALL'>Todos</Option>
              <Option value='USER'>Um usuário específico</Option>
            </Select>
          </Form.Item>

          {typeValue === 'USER' && (
            <Form.Item
              layout='horizontal'
              name='recipient'
              label='Email do usuário'
              rules={[{ required: true, message: 'Campo obrigatório!' }]}
            >
              <Input />
            </Form.Item>
          )}

          <Form.Item
            layout='horizontal'
            name='subject'
            label='Assunto'
            rules={[{ required: true, message: 'Campo obrigatório!' }]}
          >
            <Input />
          </Form.Item>
          <Form.Item label='Mensagem'>
            <Editor
              ref={editorRef}
              licenseKey='gpl'
              apiKey={`${process.env.REACT_APP_TINYMCE_KEY}`}
              tinymceScriptSrc='/tinymce/js/tinymce/tinymce.min.js'
              onInit={(evt, editor) => {
                editorRef.current = editor;
              }}
              init={{
                height: 300,
                menubar: false,
                plugins: [
                  'advlist',
                  'autolink',
                  'lists',
                  'link',
                  'image',
                  'charmap',
                  'preview',
                  'anchor',
                  'searchreplace',
                  'visualblocks',
                  'code',
                  'fullscreen',
                  'insertdatetime',
                  'media',
                  'table',
                  'code',
                  'help',
                  'wordcount',
                ],
                toolbar:
                  'undo redo | blocks | ' +
                  'bold italic forecolor | alignleft aligncenter ' +
                  'alignright alignjustify | bullist numlist outdent indent | ' +
                  'removeformat | help',
                content_style:
                  'body { font-family:Helvetica,Arial,sans-serif; font-size:14px }',
              }}
            />
          </Form.Item>

          <Form.Item>
            <Space>
              <Button type='primary' htmlType='submit'>
                Enviar
              </Button>
              <Button htmlType='button' onClick={onReset}>
                Cancelar
              </Button>
            </Space>
          </Form.Item>
        </Form>
      ),
    },
    {
      key: '2',
      label: 'MENSAGENS',
      children: (
        <Spin spinning={loading} size='large'>
          <Table
            loading={loading}
            size='small'
            dataSource={messages}
            columns={columns}
            pagination={false}
            scroll={{
              x: 'max-content',
              y: document.documentElement.scrollHeight - 250,
            }}
          />
        </Spin>
      ),
    },
  ];

  return (
    <div>
      {contextHolder}
      <h1>Notificações</h1>
      <Tabs defaultActiveKey='1' items={items} onChange={onChange} />
    </div>
  );
}
