/* eslint-disable array-callback-return */
/* eslint-disable react/no-unstable-nested-components */
/* eslint-disable jsx-a11y/control-has-associated-label */
/* eslint-disable no-nested-ternary */
/* eslint-disable prettier/prettier */

import React from 'react';
import {
  Typography,
  Flex,
  Tag,
  Timeline,
  Popover,
  Button,
  Collapse,
  Card,
  Spin,
} from 'antd';
import { CloudDownloadOutlined, ExportOutlined } from '@ant-design/icons';

import moment from 'moment';
import 'moment/locale/pt-br';
import AudioPlayer from './AudioPlayer';
import referrerFormatter from './referrerFormatter';
import sanitize from './sanitize';

const { Title } = Typography;

export default function Detalles(props: any) {
  const { log } = props;

  const color =
    log.tag === 'AI Assistant'
      ? 'purple'
      : log.tag === 'Speech'
      ? 'green'
      : log.tag === 'Tutor'
      ? 'volcano'
      : log.tag === 'Scan'
      ? 'cyan'
      : '';

  function openLink(url: string) {
    if (!url) return;

    window.open(url, '_blank');
  }

  async function downloadAudio(fileName: string, url: any) {
    if (!fileName || !url) return;
    const response = await fetch(url);
    const blob = await response.blob();
    const blobUrl = URL.createObjectURL(blob);

    const link = document.createElement('a');
    link.href = blobUrl;
    link.setAttribute('download', fileName?.replace('-user_record', ''));
    document.body.appendChild(link);
    link.click();
    URL.revokeObjectURL(blobUrl);
    document.body.removeChild(link);
  }

  const timeLineItems = [] as any;

  if (log.audioUrl) {
    timeLineItems.push({
      color: 'orange',
      label: (
        <div>
          <strong>TRANSCRIÇÃO</strong>
          {log.durationTranscription && (
            <div>
              {log.audioSize && (
                <span>{(log.audioSize / 1024 / 1024)?.toFixed(2)} MB</span>
              )}{' '}
              | Transcrito em {log.durationTranscription} segundos
            </div>
          )}
          <div style={{ paddingTop: 5 }}>
            <span>{log.transcriptionModel}</span>
          </div>
          {log.tokensTranscription && (
            <div>{log.tokensTranscription} Tokens</div>
          )}
          {log.transcriptionCost && (
            <div>USD {log.transcriptionCost?.toFixed(8)}</div>
          )}
          {log.transcriptionLanguage && (
            <div>Idioma {log.transcriptionLanguage?.toUpperCase()}</div>
          )}
          <Flex gap='large' justify='flex-end'>
            {log.promptAssistant && (
              <Popover
                placement='bottomLeft'
                title='Prompt da transcrição'
                content={
                  <div
                    style={{
                      maxWidth: 640,
                      maxHeight: 320,
                      overflowY: 'auto',
                    }}
                    dangerouslySetInnerHTML={{
                      __html: sanitize(log.promptAssistant),
                    }}
                  />
                }
              >
                <div style={{ color: 'blue' }}>Prompt</div>
              </Popover>
            )}
            {log.audioUrl && log.tag === 'Scan' && (
              <div>
                <img alt='' src={log.audioUrl} />
              </div>
            )}
          </Flex>
        </div>
      ),
      children: (
        <div>
          <div>
            {(log.tag === 'AI Assistant' || log.tag === 'Speech') && (
              <span>
                <AudioPlayer audioUrl={log.audioUrl} />{' '}
              </span>
            )}
            {log.tag === 'Scan' && (
              <span>
                <Button
                  shape='circle'
                  icon={<ExportOutlined />}
                  onClick={() => openLink(log.audioUrl)}
                />{' '}
              </span>
            )}
            <Button
              shape='circle'
              icon={<CloudDownloadOutlined />}
              onClick={() => downloadAudio(log.fileKey, log.audioUrl)}
            />
          </div>
          {(!log.difyOrder && log.tag === 'AI Assistant') ||
          log.tag === 'Speech' ||
          log.tag === 'Scan' ? (
            <Card style={{ marginTop: 6 }}>
              <div
                dangerouslySetInnerHTML={{
                  __html: sanitize(
                    log.tag === 'Scan' ? log.result : log.transcription
                  ),
                }}
              />
            </Card>
          ) : (
            <div
              style={{ marginTop: 4 }}
              dangerouslySetInnerHTML={{
                __html: sanitize(log.transcription),
              }}
            />
          )}
          <br />
          &nbsp;
          <br />
          &nbsp;
          <br />
          &nbsp;
        </div>
      ),
      position: 'left',
    });
  }

  if (log.difyOrder && log.tag === 'AI Assistant') {
    timeLineItems.push({
      label: (
        <div>
          <div>
            <strong>PROCESSAMENTO NO DIFY</strong>
            <div>
              Processamento em{' '}
              {moment.duration(log.endTimeDify - log.startTimeDify).asSeconds()}{' '}
              segundos
            </div>

            <div>{log.difyOrder.join().toLowerCase()}</div>
          </div>
          <Flex gap='large' justify='flex-end'>
            {log.order && (
              <Popover
                placement='bottomRight'
                title='Prompt Order'
                content={
                  <div
                    style={{
                      maxWidth: 640,
                      maxHeight: 320,
                      overflowY: 'auto',
                    }}
                    dangerouslySetInnerHTML={{
                      __html: sanitize(log.order),
                    }}
                  />
                }
              >
                <div style={{ color: 'blue' }}>Order</div>
              </Popover>
            )}
            {log.content && (
              <Popover
                placement='bottomRight'
                title='Conteúdo do Editor'
                content={
                  <div
                    style={{
                      maxWidth: 640,
                      maxHeight: 320,
                      overflowY: 'auto',
                    }}
                    dangerouslySetInnerHTML={{
                      __html: sanitize(log.content),
                    }}
                  />
                }
              >
                <div style={{ color: 'blue' }}>Editor</div>
              </Popover>
            )}
          </Flex>
        </div>
      ),
      children: (
        <Card>
          <div
            style={{ marginTop: 4 }}
            dangerouslySetInnerHTML={{
              __html: sanitize(log.result),
            }}
          />
        </Card>
      ),
      postion: 'left',
    });
  }

  if (log.tag === 'Tutor') {
    timeLineItems.push({
      label: (
        <div>
          <strong>PROCESSAMENTO NO DIFY</strong>
          <div style={{ color: 'blue' }}>Conteúdo</div>
          <div
            dangerouslySetInnerHTML={{
              __html: sanitize(log.content),
            }}
          />
        </div>
      ),
      children: (
        <div>
          <Card>
            <div
              style={{ marginTop: 4 }}
              dangerouslySetInnerHTML={{
                __html: sanitize(log.result),
              }}
            />
          </Card>
        </div>
      ),
      postion: 'left',
    });
  }

  if (!log.processing) {
    timeLineItems.push({
      color:
        log.errorDify || log.errorTranscript || log.error || log.responseStatus
          ? 'red'
          : 'green',
      label: (
        <div>
          <strong>STATUS</strong>
          {log.durationTotal && (
            <div>Finalizado em {log.durationTotal.toFixed(2)} segundos</div>
          )}
        </div>
      ),
      children: <Status log={log} />,
    });
  }

  function extractAndSortLogStage() {
    const logStageEntries = Object.entries(log).filter(([key]) =>
      key.startsWith('logStage')
    );

    const arr = logStageEntries.map(([stage, timestamp]) => ({
      timestamp: Number(timestamp),
      stage,
    }));

    arr.sort((a, b) => a.timestamp - b.timestamp);

    return arr;
  }

  return (
    <div>
      <center>
        <Tag color={color}>{log.tag.toUpperCase()}</Tag>
      </center>
      &nbsp;
      <Timeline
        pending={log.processing ? 'Processando...' : ''}
        mode='left'
        items={timeLineItems}
      />
      <br />
      <Flex gap='large' justify='center' wrap>
        <div>
          <small>RUNTIME</small>
          <Title style={{ margin: 0 }} level={5}>
            {log.runtime}
          </Title>
        </div>

        <div>
          <small>DEVICE</small>
          <Title style={{ margin: 0 }} level={5}>
            {log.platform}
          </Title>
        </div>

        <div>
          <small>NAVEGADOR</small>
          <Title style={{ margin: 0 }} level={5}>
            {log.browser}
          </Title>
        </div>

        <div>
          <small>OS</small>
          <Title style={{ margin: 0 }} level={5}>
            {log.OS} ({log.OSVersion})
          </Title>
        </div>

        <div>
          <small>APP</small>
          <Title style={{ margin: 0 }} level={5}>
            {log.app}
          </Title>
        </div>

        {log.referrer && (
          <div>
            <small>ORIGEM</small>
            <Title style={{ margin: 0 }} level={5}>
              {log.referrer}
            </Title>
          </div>
        )}

        <div>
          <small>IFRAME</small>
          <Title style={{ margin: 0 }} level={5}>
            {log.iframe ? 'SIM' : 'NÃO'}
          </Title>
        </div>
      </Flex>
      <br />
      {false && <pre>{JSON.stringify(extractAndSortLogStage(), null, 3)}</pre>}
      <Collapse
        items={[
          {
            key: '1',
            label: 'JSON LOG',
            children: (
              <div style={{ overflowWrap: 'break-word' }}>
                <pre>{JSON.stringify(log, null, 4)}</pre>
              </div>
            ),
          },
        ]}
      />
    </div>
  );
}

export function Status(props: any) {
  const { log } = props;

  if (log.processing) {
    return <Spin />;
  }
  if (log.errorDify) {
    return (
      <Popover
        placement='left'
        content={
          <div
            style={{
              maxWidth: 640,
              maxHeight: 320,
              overflowY: 'auto',
            }}
          >
            {log.errorDify}
          </div>
        }
      >
        <span style={{ color: 'red' }}>
          <strong>DIFY</strong>
          <br />
          <small>ERROR</small>
        </span>
      </Popover>
    );
  }
  if (log.errorTranscript) {
    return <span style={{ color: 'red' }}>{log.errorTranscript}</span>;
  }
  if (log.error && log.error !== '{}') {
    return <strong style={{ color: 'red' }}>{log.error}</strong>;
  }
  if (log.responseStatus === 408) {
    return (
      <span style={{ color: 'red' }}>
        <strong>408</strong>
        <br />
        <small>TIMEOUT</small>
      </span>
    );
  }
  if (log.responseStatus === 401) {
    return (
      <span style={{ color: 'red' }}>
        <strong>401</strong>
        <br />
        <small>TOKEN</small>
      </span>
    );
  }
  if (log.responseStatus === 502) {
    return (
      <span style={{ color: 'red' }}>
        <strong>502</strong>
        <br />
        <small>Bad Gateway</small>
      </span>
    );
  }
  if (log.responseStatus === 503) {
    return (
      <span style={{ color: 'red' }}>
        <strong>503</strong>
        <br />
        <small>Unavailable</small>
      </span>
    );
  }
  if (log.responseStatus) {
    return <strong style={{ color: 'red' }}>{log.responseStatus}</strong>;
  }
  if (log.durationTotal && !log.result) {
    return (
      <strong style={{ color: 'red' }}>
        <small>SEM</small>
        <br />
        <small>RESPOSTA</small>
      </strong>
    );
  }
  return <span>OK</span>;
}
