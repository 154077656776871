/* eslint-disable prettier/prettier */
import React from 'react';
import { Pie } from '@ant-design/plots';

export default function PieChart(props: any) {
  const { data } = props;

  const config = {
    width: 400,
    height: 400,
    data,
    angleField: 'count',
    colorField: 'name',
    radius: 0.8,
    label: {
      text: (d: any) => `${d.name}\n ${d.count}`,
      position: 'spider',
    },
  };

  return <Pie {...config} />;
}
